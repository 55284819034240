// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "D_fD";
export var mvpAppDevCaseSection = "D_fM";
export var mvpAppDevChoiceSection = "D_fK";
export var mvpAppDevClientQuotes = "D_fN";
export var mvpAppDevCostSection = "D_fH";
export var mvpAppDevExpertiseSection = "D_fL";
export var mvpAppDevIndustriesSection = "D_fB";
export var mvpAppDevPracticesSection = "D_fJ";
export var mvpAppDevPrimeSection = "D_fy";
export var mvpAppDevProcessSection = "D_fF";
export var mvpAppDevServicesSection = "D_fz";
export var mvpAppDevTechSection = "D_fG";
export var mvpAppDevTypesSection = "D_fC";